import { Button, Collapse, ICollapseProps } from '@msdyn365-commerce-modules/utilities';
import classnames from 'classnames';
import * as React from 'react';
import { IQuickOrderDrawerProps } from './quick-order-drawer-props';

export interface IQuickOrderDrawerState {
    isOpen: boolean;
}

export type GlyphPlacement = 'start' | 'end';

/**
 * Quick Order Drawer - This is a full width uncontrolled collapse where the button glyph changes
 * when the drawer is open and closed.
 */

export default class QuickOrderDrawer extends React.Component<IQuickOrderDrawerProps, IQuickOrderDrawerState> {
    public state: IQuickOrderDrawerState;

    private readonly drawerOpenGlyph: string;

    private readonly drawerCloseGlyph: string;

    private readonly drawerGlyphClass: string;

    private isManuallyToggled: boolean;

    constructor(props: IQuickOrderDrawerProps) {
        super(props);
        this._toggle = this._toggle.bind(this);
        this.isManuallyToggled = false;
        this.state = { isOpen: (props.collapseProps && props.collapseProps.isOpen) || false };
        this.drawerGlyphClass = classnames('drawer__glyph',
                                           this.props.toggleGlyphClassName ?
                this.props.toggleGlyphClassName : '');

        if (this.props.animateGlyph) {
            this.drawerOpenGlyph = classnames(this.drawerGlyphClass,
                                              this.props.openGlyph ? this.props.openGlyph : (this.props.closeGlyph ? this.props.closeGlyph : ''));
            this.drawerCloseGlyph = this.drawerOpenGlyph;
        } else {
            this.drawerOpenGlyph = classnames(this.drawerGlyphClass,
                                              this.props.openGlyph ? this.props.openGlyph : '');
            this.drawerCloseGlyph = classnames(this.drawerGlyphClass,
                                               this.props.closeGlyph ? this.props.closeGlyph : '');
        }
    }

    public render(): JSX.Element {
        const collapseProps = { ...this.props.collapseProps } as ICollapseProps;

        // If the drawer is manually toggled by pressing the button, then we use the internal state
        if (collapseProps.isOpen === undefined || this.isManuallyToggled) {
            collapseProps.isOpen = this.state.isOpen;
        } else {
            this.state.isOpen = collapseProps.isOpen;
        }
        collapseProps.children = this.props.children;
        const drawerClass = classnames('drawer', this.props.className ? this.props.className : '');
        this.isManuallyToggled = false;
        return (
            <div className={drawerClass}>
                <Button
                    {...this.props.toggleButtonProps}
                    onClick={this._toggle}
                    aria-expanded={collapseProps.isOpen}
                    block
                    disabled={this.props.disabled}
                    className='drawer__button'
                    color='secondary'
                >
                    {this._generateDrawerButtonInnerMarkup(collapseProps.isOpen)}
                </Button>
                <Collapse {...collapseProps} />
            </div>
        );
    }

    private _generateDrawerButtonInnerMarkup(collapseState: boolean): JSX.Element {
        const drawerButtonTextClass = classnames('drawer__buttontext', this.props.toggleButtonClassName ? this.props.toggleButtonClassName : '');
        return (
            <>
                <h2 className={classnames(drawerButtonTextClass, '__start ms-quick-order__heading')}>
                    {this.props.moduleHeadingText}
                </h2>
                <span className={classnames(drawerButtonTextClass, '__start ms-quick-order__description')}>
                    {this.props.moduleDescriptionText}
                </span>
                {this._generateGlyphMarkup('drawer__glyph__end', collapseState)}
            </>
        );
    }

    private _generateGlyphMarkup(location: string, collapseState: boolean): JSX.Element {
        let glyphClass = collapseState ? this.drawerOpenGlyph : this.drawerCloseGlyph;
        glyphClass = `${glyphClass} ${location}`;
        return (
            <span className={glyphClass} />
        );
    }

    private _toggle(e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>): void {
        e.preventDefault();
        this.setState({ isOpen: !this.state.isOpen });
        this.isManuallyToggled = true;
        if (this.props.onToggle) {
            this.props.onToggle(this.state);
        }
    }
}
